import {decrypt } from "@/utils/aesUtil.js";
export default {
	name: "Home",
	data() {
		return {
			getPatient: {
				userType: Number,
				uuid: 'string',
				account: '',
				page: 1,
				size: 5
			},
			//患者管理数据
			PatientTableData: [],
			//医生管理数据
			DoctorTableData: [],
			//关联医生
			doctorShow: Number,
			//禁用按钮
			statusShow: Number,
			//用户类型
			userType:null,
			//垂体泵人数
			PituitaryPump:null,
			//胰岛素泵人数
			InsulinPump:null,
			//医生总人数
			DoctorNumber:null,
			//患者总人数
			PatientNumber:null,
			//CGM总人数
			CgmNumber:null,
			//cgm 分享患者uuid
			ufu: null,
			//申请查看
			applyForCheck: false,
			patientname: null,
		}
	},
	created() {
		this.userType = sessionStorage.getItem('userType');
		this.ufu = this.$route.query.ufu;
		//申请查看
		this.getapplyForCheck();
		this.getPituitaryPump();
		this.getInsulinPump();
		this.getPatientList();
		this.getDoctorList();
		this.getDoctorNumber();
		this.getPatientNumber();
		this.getCgmNumber();
		this.roleShow();
		//console.log(this.userType)
	},
	methods: {
		//申请查看显示隐藏
		getapplyForCheck() {
			console.log(this.ufu)
			if (this.ufu !== undefined && this.ufu !== '' && this.ufu !== null) {
				this.$axios
					.get("/api/sys/web/user/encrypt/detail/" + this.ufu)
					.then(response => {
						if (response.data.code === 200) {
							var popped = sessionStorage.getItem('popped')
							if( popped == 1 ){
								this.applyForCheck = false
							}else{
								this.applyForCheck = true
							}
							if (response.data.data.name !== null) {
								this.patientname = decrypt(response.data.data.name);
							} else {
								this.patientname = decrypt(response.data.data.account)
							}
						}
					})
			}
		},
		//申请查看功能
		doapplyForCheck() {
			var params = {
				userFollowUpUuid: sessionStorage.getItem('userUuid') ,
				userUuid:this.ufu,
			}
			this.$axios({
				method: "post",
				url: "/api/cgm/web/cgmPatientFollowUp/applyFor", // 接口地址
				data: params
			}).then(res => {
				if (res.data.code === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
					});
					this.userFollowUpUuid = null;
					this.applyForCheck = false;
					sessionStorage.setItem("popped", 1);
				} else if (res.data.code === 500) {
					this.$message({
						message: "正在审核",
						type: "success",
					});
					this.userFollowUpUuid = null;
					this.applyForCheck = false;
					sessionStorage.setItem("popped", 1);
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//患者管理--更多
		PatientMore(){
			this.$router.push({
				path: '/patient/patient-list',
			});
		},
		//医生管理--更多
		DoctorMore(){
			this.$router.push({
				path: 'doctor-list',
			});
		},
		//审核带参跳转页面
		doIsVerifiedy(uuid){
			this.$router.push({
				path: 'doctor-detail',
				query: {
					getDoctorManageUuid: uuid,
				}
			});
		},
		//CGM总人数
		getCgmNumber(){
			this.$axios.get('/api/cgm/web/patientDevice/countCgmUser').then(res => {
				if (res.data.code === 200) {
					this.CgmNumber = res.data.data
					//console.log(this.PatientNumber)
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//患者总人数
		getPatientNumber(){
			this.$axios.get('/api/sys/web/user/countUser/' + 3).then(res => {
				if (res.data.code === 200) {
					this.PatientNumber = res.data.data
					//console.log(this.PatientNumber)
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//医生总人数
		getDoctorNumber(){
			this.$axios.get('/api/sys/web/user/countUser/' +2).then(res => {
				if (res.data.code === 200) {
					this.DoctorNumber = res.data.data
					//console.log(this.DoctorNumber)
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//关联医生跳转页面
		doRelevanceDoctor(uuid){
			console.log(uuid)
			this.$router.push({
				path: '/patient/relevance-doctor',
				query: {
					getDoctorManageUuid: uuid,
				}
			});
		},
		//发射器已激活数量
		getPituitaryPump(){
			this.$axios.get('/api/device/web/cgmDevice/activeCount/1').then(res => {
				if (res.data.code === 200) {
					this.PituitaryPump = res.data.data
					//console.log(this.PituitaryPump)
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//传感器已激活数量
		getInsulinPump(){
			this.$axios.get('/api/device/web/cgmDevice/activeCount/2').then(res => {
				if (res.data.code === 200) {
					this.InsulinPump = res.data.data
					//console.log(this.InsulinPump)
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},

		//根据角色显示隐藏列表功能
		//用户类型：1管理员；2医生；3患者
		roleShow() {
			if (this.userType == 2) {
				this.doctorShow = true;
				this.statusShow = false;
			} else {
				this.doctorShow = false;
				this.statusShow = true;
			}
		},
		//垂体泵按钮带参跳转页面
		doPituitary(uuid) {
			//console.log(uuid);
			this.$router.push({
				path: '/patient/patient-detail',
				query: {
					getPatientDetailUuid: uuid,
					userType: 1,
					activeName:'first'
				}
			});
		},
		//胰岛素泵按钮带参跳转页面
		doInsulin(uuid) {
			//console.log(uuid);
			this.$router.push({
				path: '/patient/patient-detail',
				query: {
					getPatientDetailUuid: uuid,
					userType: 1,
					activeName:'second'
				}
			});
		},
		//CGM血糖仪钮带参跳转页面
		doGlucometer(uuid) {
			//console.log(uuid);
			this.$router.push({
				path: '/patient/patient-detail',
				query: {
					getPatientDetailUuid: uuid,
					userType: 1,
					activeName:'third'
				}
			});
		},
		//禁用用户
		doForbidden(uuid) {
			this.$confirm("您确定删除吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/user/del/' + uuid).then(res => {
					if (res.data.code === 200) {
						this.getPatientList();
						this.getDoctorList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		//CGM 按钮跳转页面
		doCgmReport(uuid){
			this.$router.push({
				path: '/patient/patient-cgm-report',
				query: {
					getPatientDetailUuid: uuid,
				}
			});
		},
		//启用用户
		dostartUsing(uuid) {
			this.$confirm("您确定启用吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/user/status/' + uuid + "/" +1).then(res => {
					if (res.data.code === 200) {
						this.getPatientList();
						this.getDoctorList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		/*获取患者列表数据*/
		getPatientList() {
			this.PatientTableData.splice(0, this.PatientTableData.length); //清空数组
			this.getPatient.uuid = sessionStorage.getItem('userUuid');
			this.getPatient.userType = sessionStorage.getItem('userType');
			this.$axios({
				method: "post",
				url: "/api/sys/web/user/encrypt/page", // 接口地址
				data: this.getPatient
			}).then(res => {
				if (res.data.code === 200) {
					this.PatientTableData = res.data.data;
					for(var i=0;i<this.PatientTableData.length;i++){
						var account=this.PatientTableData[i].account;
						if(account){
							account=decrypt(account);
							this.PatientTableData[i].account= account;
						}
						var mobile = this.PatientTableData[i].mobile;												
						if(mobile){
							mobile=decrypt(mobile);							
							mobile = mobile.substring(0,3) + "****" + mobile.substring(7,12);
							this.PatientTableData[i].mobile= mobile;
						}
					}
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					//console.log(res.data)
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//关联患者跳转页面
		doRelevancePatient(uuid){
			this.$router.push({
				path: '/patient/doctor-patient',
				query: {
					getDoctorManageUuid: uuid,
				}
			});
		},
		/*获取医生列表数据*/
		getDoctorList() {
			this.DoctorTableData.splice(0, this.DoctorTableData.length); //清空数组
			this.$axios({
				method: "post",
				url: "/api/sys/web/doctor/page", // 接口地址
				data: this.getPatient
			}).then(res => {
				if (res.data.code === 200) {
					this.DoctorTableData = res.data.data;
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					console.log(this.DoctorTableData)
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		/* checkCGMDeviceType(row, typ) {
			let ret = false;
			if(typ === 3) {
				// cgm
				for(let i = 0; i < row.deviceList.length; i++) {
					if(row.deviceList[i].deviceType === 3 || row.deviceList[i].deviceType === 4) {
						ret = true;
						break;
					}
				}
			} else if (typ === 1) {
				// 垂体泵
				for(let i = 0; i < row.deviceList.length; i++) {
					if(row.deviceList[i].deviceType === 1) {
						ret = true;
						break;
					}
				}
			} else if (typ === 2) {
				// 胰岛素泵
				for(let i = 0; i < row.deviceList.length; i++) {
					if(row.deviceList[i].deviceType === 2) {
						ret = true;
						break;
					}
				}
			}
			return ret;
		} */
	}
}
